import React from 'react';
import { observer } from 'mobx-react';
import { Button, message, Modal, Tooltip } from 'antd';
import moment from 'moment';

function PromotionConfirmationMessage({
  dateStart,
  dateEnd,
  timeStart,
  timeEnd,
}) {
  const isFullday = timeStart === timeEnd;

  const dateDescriptionString = isFullday
    ? `Ежедневно, в период с ${dateStart} по ${dateEnd}, будут действовать акционные цены. ${dateEnd} будут установлены стандартные цены.`
    : `В период с ${dateStart} по ${dateEnd}, с ${timeStart} до ${timeEnd} (${moment().format(
        'Z',
      )}) будут действовать акционные цены. Вне этого времени, будут действовать стандартные цены группы.`;

  return (
    <span>
      {dateDescriptionString}
      <br /> <br />
      Активировать акцию?
    </span>
  );
}

export const ActivatePromotionAction = observer(({ group }) => {
  const isPromotionActive = group.isActivePromotion;
  const { isPromotionSettingsReady } = group;
  const startDate = group.promotion?.startDate;
  const endDate = group.promotion?.endDate;

  const notificationData = isPromotionActive
    ? {
        title: 'Подтверждение отмены акции',
        okText: 'Отменить акцию',
        cancelText: 'Назад',
        buttonText: 'Отменить акцию',
        buttonType: 'danger',
        content: (
          <span>
            На оборудование будут загружены стандартные цены. <br />
            <br />
            Отменить проведение акции?
          </span>
        ),
      }
    : {
        title: 'Подтверждение проведения акции',
        okText: 'Активировать',
        cancelText: 'Назад',
        buttonText: 'Активировать акцию',
        buttonType: 'primary',
        content: (
          <PromotionConfirmationMessage
            dateStart={startDate?.format('YYYY-MM-DD')}
            dateEnd={endDate?.format('YYYY-MM-DD')}
            timeStart={startDate?.format('HH:mm')}
            timeEnd={endDate?.format('HH:mm')}
          />
        ),
      };

  return (
    <Tooltip
      title={
        isPromotionSettingsReady
          ? ''
          : 'Убедитесь, что в группе есть устройства и напитки, установлены настройки акции, что для каждого напитка задана акционная цена и дата окончания акции еще акутальна.'
      }
    >
      <Button
        disabled={!isPromotionSettingsReady}
        type="primary"
        danger={notificationData.buttonType === 'danger'}
        onClick={() => {
          Modal.confirm({
            width: 540,
            title: notificationData.title,
            icon: null,
            okText: notificationData.okText,
            cancelText: notificationData.cancelText,
            content: notificationData.content,
            onOk: () => {
              if (isPromotionActive) {
                group
                  .disablePromotion()
                  .then(() => {
                    message.success('Акция успешно отменена!');
                  })
                  .catch(() => {
                    message.error('Не удалось отменить акцию!');
                  });
              } else {
                group
                  .enablePromotion()
                  .then(() => {
                    message.success('Акция успешно активирована!');
                  })
                  .catch(() => {
                    message.error('Не удалось активировать акцию!');
                  });
              }
            },
          });
        }}
      >
        {notificationData.buttonText}
      </Button>
    </Tooltip>
  );
});
