import React from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import Icon from 'elements/icon';
import styles from './styles.module.scss';

const CLASS_NAME_BY_TYPE = {
  warning: styles['container-warning'],
  success: styles['container-success'],
  danger: styles['container-danger'],
  normal: styles['container-normal'],
};

const DEFAULT_TYPE = 'normal';

const getClassNameByType = (type) =>
  CLASS_NAME_BY_TYPE[type] || CLASS_NAME_BY_TYPE[DEFAULT_TYPE];

export function SystemNotification({ type, text, onClose }) {
  const classNameByType = getClassNameByType(type);

  return (
    <div className={classNames([styles.container, classNameByType])}>
      <span>{text}</span>
      <button type="button" className={styles.close} onClick={onClose}>
        <Icon name="close-outline" size={24} />
      </button>
    </div>
  );
}

function SystemNotificationContainer({ session }) {
  if (!session.systemNotification.isShown) {
    return null;
  }

  return (
    <SystemNotification
      type={session.systemNotification.type}
      text={session.systemNotification.text}
      onClose={session.systemNotification.hide}
    />
  );
}

export default inject('session')(observer(SystemNotificationContainer));
