import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button, Space, Modal, message, Popconfirm } from 'antd';

import Location from 'elements/location';
import Calendar from 'elements/calendar';
import Icon from 'elements/icon';

import Tech from './tech';
import Commerce from './commerce';
import Voltage from './voltage';
import { ControllerStatusModal } from './controllerStatusModal/controllerStatusModal';

import style from './index.module.scss';

const DeviceTitleAction = withRouter(
  inject(({ element, session }) => ({ element, session }))(
    observer(
      ({
        history,
        session: { events, beverages, points, permissions },
        element: {
          id,
          isOn,
          salePointLocation,
          salePointAddress,
          salePointId,
          restartInProcess,
          restart,
          controllerStatus,
        },
      }) => {
        const [controllerStatusVisible, setControllerStatusVisible] =
          useState(false);

        return (
          <div className={style.actions}>
            <Space size={8}>
              <Location
                location={salePointLocation}
                address={salePointAddress}
              />
            </Space>
            <Space>
              {permissions.checkPermission('devices', 'usb_relay') && (
                <Popconfirm
                  overlayStyle={{ width: 300 }}
                  disabled={restartInProcess || !isOn}
                  onConfirm={() => {
                    restart().then((res) => {
                      if (res.error) {
                        message.error(res.error);
                      } else {
                        message.success('Процесс перезапуска успешно запущен!');
                      }
                    });
                  }}
                  title="Вы уверены, что хотите перезагрузить устройство?"
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button disabled={restartInProcess || !isOn}>
                    Перезагрузить
                  </Button>
                </Popconfirm>
              )}
              <Button onClick={() => history.push(events.getPathForDevice(id))}>
                События
              </Button>
              <Button
                onClick={() => history.push(beverages.getPathForDevice(id))}
              >
                Наливы
              </Button>
              <Button
                onClick={() =>
                  history.push(points.getPathForPoint(salePointId))
                }
              >
                Объект
              </Button>
              <Button onClick={() => setControllerStatusVisible(true)}>
                <span className={isOn ? style['link-on'] : style['link-off']}>
                  <Icon
                    size={20}
                    name={isOn ? 'wifi-outline' : 'wifi-off-outline'}
                  />
                </span>
              </Button>
            </Space>
            <ControllerStatusModal
              onCancel={() => setControllerStatusVisible(false)}
              visible={controllerStatusVisible}
              controllerStatus={controllerStatus}
            />
          </div>
        );
      },
    ),
  ),
);

const DeviceOverview = withRouter(
  inject('element')(
    observer(
      ({
        element: {
          details: { allClearancesDates },
        },
        history: { goBack },
        match: {
          params: { action },
        },
      }) => {
        switch (action) {
          case 'commerce':
            return <Commerce />;
          case 'voltage':
            return <Voltage />;
          default:
            return (
              <>
                <Modal
                  title="Календарь очисток"
                  visible={action === 'calendar'}
                  onOk={goBack}
                  onCancel={goBack}
                  wrapClassName={style.calendar}
                  footer={null}
                >
                  <Calendar clearances={allClearancesDates} />
                </Modal>
                <Tech />
              </>
            );
        }
      },
    ),
  ),
);

export { DeviceTitleAction, DeviceOverview };
