import { observable } from 'mobx';
import { getPrices, deletePrice, patchPrice } from 'services/price';

class Prices extends Map {
  @observable isLoaded = false;

  constructor(session) {
    super();

    this.session = session;
    getPrices(this.session)()
      .then(({ results }) =>
        results.forEach((price) => this.set(price.id, price)),
      )
      .then(() => {
        this.isLoaded = true;
      });
  }

  fetchPrices = () => {
    getPrices(this.session)().then(({ results }) =>
      results.forEach((price) => {
        const priceData = this.get(price.id);
        if (priceData && priceData.value !== price.value) {
          priceData.value = price.value;
        }

        if (!priceData) {
          this.set(price.id, price);
        }
      }),
    );
  };

  getPromotionPricesByGroupId(groupId) {
    if (!this.isLoaded) {
      return undefined;
    }
    return [...this.values()].filter(
      ({ groupId: id, promotionId }) => id === groupId && promotionId,
    );
  }

  getBySet(pricesIdSet) {
    if (!this.isLoaded) {
      return undefined;
    }
    return [...this.values()].filter(({ id }) => pricesIdSet.has(id));
  }

  remove = deletePrice;

  patch = patchPrice;

  add(prices) {
    for (const price of prices) {
      this.set(price.id, price);
    }
  }
}

export default Prices;
