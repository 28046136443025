import React, { useState } from 'react';
import { Checkbox, Tooltip, Table } from 'antd';
import classNames from 'classnames';
import { withSize } from 'react-sizeme';

import classes from './style.module.scss';

function SelectableTable({
  columns,
  onSelect,
  dataSource,
  className,
  disabledText,
  isSelectAll = false,
  value: externalValue,
}) {
  const internalValue = useState(new Set());
  const [selected, setSelected] = externalValue
    ? [externalValue, () => {}]
    : internalValue;
  const onSelectRow = (id, disabled) =>
    disabled
      ? () => {}
      : ({ target: { checked: value } }) => {
          selected[value ? 'add' : 'delete'](id);
          setSelected(new Set([...selected.values()]));
          onSelect(selected);
        };

  const handleCheckAll = ({ target: { checked: value } }) => {
    dataSource?.forEach(({ key, disabled }) => {
      if (disabled) return;
      selected[value ? 'add' : 'delete'](key);
    });
    setSelected(new Set([...selected.values()]));
    onSelect(selected);
  };

  const ds = dataSource?.map((datum) => ({
    ...datum,
    className: classNames(datum.className, {
      [classes.selected]: selected.has(datum.key),
    }),
    checkbox: {
      isPicked: selected.has(datum.key),
      setPicked: onSelectRow(datum.key, datum.disabled),
      disabled: datum.disabled || false,
    },
  }));

  const columnsWithCheckbox = [
    {
      dataIndex: 'checkbox',
      title: isSelectAll ? <Checkbox onChange={handleCheckAll} /> : '',
      width: 50,
      align: 'center',
      render: ({ isPicked, setPicked, disabled }) => (
        <Tooltip title={disabled ? disabledText : undefined}>
          <Checkbox
            disabled={disabled}
            defaultChecked={isPicked}
            checked={isPicked}
            onChange={setPicked}
          />
        </Tooltip>
      ),
    },
    ...columns,
  ];

  return (
    <Table
      sticky
      className={className}
      pagination={false}
      columns={columnsWithCheckbox}
      dataSource={ds}
      rowClassName={({ className: rowClassName }) => rowClassName}
    />
  );
}

export default withSize()(SelectableTable);
