import { observable } from 'mobx';
import {
  getPackagesPermissions,
  getBeveragesPermissions,
} from 'services/packages';
import { getEventReferencesPermissions } from 'services/events';
import { getDevicesPermissions } from 'services/device';

class Permissions {
  @observable permissions = {
    packages: [],
    beverages: [],
    eventReferences: [],
    devices: [],
  };

  constructor() {
    this.registerScopePermissions('packages', getPackagesPermissions);
    this.registerScopePermissions('beverages', getBeveragesPermissions);
    this.registerScopePermissions(
      'eventReferences',
      getEventReferencesPermissions,
    );
    this.registerScopePermissions('devices', getDevicesPermissions);
  }

  checkPermission(scope, permission) {
    if (!this.permissions[scope] || !Array.isArray(this.permissions[scope])) {
      console.warn(`Undefined permission scope ${scope}!`);
      return false;
    }

    return this.permissions[scope].includes(permission);
  }

  isAllowView(scope) {
    return this.checkPermission(scope, 'view');
  }

  isAllowDelete(scope) {
    return this.checkPermission(scope, 'delete');
  }

  registerScopePermissions = (scope, getter) => {
    getter().then((permissions) => {
      this.permissions[scope] = permissions;
    });
  };
}

export default Permissions;
