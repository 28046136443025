import React from 'react';
import { Space } from 'antd';
import classNames from 'classnames';

import Icon from 'elements/icon';

import style from './index.module.scss';

function getClassNameByIsOnFlag(isOn) {
  return classNames([style.link, isOn ? style['link-on'] : style['link-off']]);
}

function DeviceStatus({ isOn, announce, iconOnly = false }) {
  return (
    <Space size={4}>
      <span className={getClassNameByIsOnFlag(isOn)}>
        <Icon name={isOn ? 'wifi-outline' : 'wifi-off-outline'} />
      </span>
      {announce}
      {!iconOnly && (
        <span className={getClassNameByIsOnFlag(isOn)}>
          {isOn ? 'online' : 'offline'}
        </span>
      )}
    </Space>
  );
}

export default DeviceStatus;
