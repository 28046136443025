import React from 'react';

import GenericPage from 'elements/genericPage';

function Devices() {
  return (
    <GenericPage
      storageName="eventTypes"
      tableTitle="Настройка событий"
      allLinkText="Настройка событий"
    />
  );
}

export default Devices;
