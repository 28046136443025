import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
  display: inline-block;
  background-color: ${({ color }) => color};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  margin-right: 4px;
`;

export function PointStatus({ size, color }) {
  return <Circle color={color} size={size} />;
}
