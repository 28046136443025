import React from 'react';
import { Button, Popconfirm } from 'antd';
import { observer } from 'mobx-react';

import Icon from 'elements/icon';
import Format from 'elements/format';

import CommitInputCell, {
  renderPriceTextValue,
  priceTransformer,
} from './commitInputCell';

const PromotionPrice = observer(({ sendPromotionValue, promotionPrice }) => (
  <CommitInputCell
    value={promotionPrice?.value}
    sendValue={sendPromotionValue}
    transformer={priceTransformer}
    renderTextValue={renderPriceTextValue}
  />
));

export const DEFAULT_COLUMNS = [
  {
    title: 'PLU',
    dataIndex: 'plu',
    width: 70,
    sorter: (a, b) => b.plu - a.plu || a.key - b.key,
  },
  {
    title: 'доп. PLU',
    dataIndex: 'extPlu',
    width: 120,
    sorter: (a, b) => b.extPlu - a.extPlu || a.key - b.key,
  },
  {
    title: 'Название',
    dataIndex: 'name',
    render: (name) => <Format>{name}</Format>,
    sorter: (a, b) => {
      if (typeof a.name === 'string' && typeof b.name === 'string') {
        return a.name.localeCompare(b.name) || a.key - b.key;
      }
      return a.key - b.key;
    },
    defaultSortOrder: 'ascend',
  },
  {
    title: 'НДС',
    width: 100,
    dataIndex: 'nds',
    render: (v) => <Format>{v}</Format>,
  },
  {
    title: 'Код клиента',
    dataIndex: 'codeExt',
    width: 150,
    render: (codeExt, { sendCodeExt }) => (
      <CommitInputCell value={codeExt} sendValue={sendCodeExt} />
    ),
    sorter: (a, b) => b.codeExt - a.codeExt || a.key - b.key,
  },
  {
    title: 'Цена',
    dataIndex: 'value',
    width: 150,
    render: (value, { sendValue }) => (
      <CommitInputCell
        value={value}
        sendValue={sendValue}
        transformer={priceTransformer}
        renderTextValue={renderPriceTextValue}
      />
    ),
    sorter: (a, b) => b.value - a.value || a.key - b.key,
  },
  {
    title: 'Цена по акции',
    dataIndex: 'promotionPrice',
    width: 150,
    render: (promotionPrice, { sendPromotionValue, codeExt, promotionId }) => {
      if (promotionId) {
        return (
          <PromotionPrice
            sendPromotionValue={sendPromotionValue}
            promotionPrice={promotionPrice}
            promotionId={promotionId}
          />
        );
      }

      return '-';
    },
    sorter: (a, b) =>
      (b.promotionPrice?.value || 0) - (a.promotionPrice?.value || 0) ||
      a.key - b.key,
  },
  {
    title: '',
    dataIndex: 'rm',
    render: (rm) => (
      <Popconfirm
        placement="left"
        title="Отмена операции невозможна. Продолжить удаление?"
        onConfirm={rm}
        okText="Да"
        cancelText="Нет"
      >
        <Button icon={<Icon size={20} name="trash-2-outline" />} type="text" />
      </Popconfirm>
    ),
  },
];

export const PROMOTION_COLUMNS = [
  {
    title: 'PLU',
    dataIndex: 'plu',
    width: 70,
    sorter: (a, b) => b.plu - a.plu || a.key - b.key,
  },
  {
    title: 'доп. PLU',
    dataIndex: 'extPlu',
    width: 120,
    sorter: (a, b) => b.extPlu - a.extPlu || a.key - b.key,
  },
  {
    title: 'Название',
    dataIndex: 'name',
    render: (name) => <Format>{name}</Format>,
    sorter: (a, b) => {
      if (typeof a.name === 'string' && typeof b.name === 'string') {
        return a.name.localeCompare(b.name) || a.key - b.key;
      }
      return a.key - b.key;
    },
    defaultSortOrder: 'ascend',
  },
  {
    title: 'НДС',
    dataIndex: 'nds',
    render: (v) => <Format>{v}</Format>,
  },
  {
    title: 'Код клиента',
    dataIndex: 'codeExt',
    width: 150,
    render: (v) => <Format>{v}</Format>,
    sorter: (a, b) => b.codeExt - a.codeExt || a.key - b.key,
  },
  {
    title: 'Цена',
    dataIndex: 'value',
    width: 150,
    render: (value, { codeExt }) => (
      <span>
        <Format isCost>{value}</Format>
      </span>
    ),
    sorter: (a, b) => b.value - a.value || a.key - b.key,
  },
  {
    title: 'Цена по акции',
    dataIndex: 'promotionPrice',
    width: 150,
    render: (promotionPrice, { codeExt }) =>
      promotionPrice ? <Format isCost>{promotionPrice?.value}</Format> : '–',
    sorter: (a, b) =>
      (b.promotionPrice?.value || 0) - (a.promotionPrice?.value || 0) ||
      a.key - b.key,
  },
];
