/* eslint-disable camelcase */
import moment from 'moment';

import { post, get } from 'utils/request';

async function getDeviceSyncStatus({ deviceId }) {
  const requestPath = `/refs/devices/${deviceId}/sync_status/`;

  const { sync_status, is_promotion_price, is_actual_price, sync_date } =
    await get(requestPath);

  return Promise.resolve({
    status: sync_status,
    isPromotionPrice: is_promotion_price,
    isActualPrice: is_actual_price,
    syncDate: moment(sync_date),
  });
}

function getPromotions() {
  const requestPath = `/refs/promotions/`;

  return get(requestPath).then((resultAll) =>
    resultAll.map((result) => ({
      id: result.id,
      name: result.name,
      startDate: result.start_at ? moment(result.start_at) : undefined,
      endDate: result.end_at ? moment(result.end_at) : undefined,
      groupId: result.price_group,
      active: result.active,
      allday: result.allday,
    })),
  );
}

function getPromotionInfo({ groupId }) {
  const requestPath = `/refs/price_groups/${groupId}/promotion/`;
  const defaultPromotion = {
    id: undefined,
    name: '',
    startDate: undefined,
    endDate: undefined,
    active: false,
    allday: true,
  };

  return get(requestPath)
    .then((result) =>
      result.id
        ? {
            id: result.id,
            name: result.name,
            startDate: result.start_at ? moment(result.start_at) : undefined,
            endDate: result.end_at ? moment(result.end_at) : undefined,
            active: result.active,
            allday: result.allday,
          }
        : defaultPromotion,
    )
    .catch((err) => defaultPromotion);
}

function updatePromotion({ allday, groupId, startDate, endDate }) {
  const requestPath = `/refs/price_groups/${groupId}/promotion/`;
  const data = {
    allday,
    start_at: startDate,
    end_at: endDate,
  };

  return post(requestPath, data);
}

function enablePromotion({ groupId }) {
  const requestPath = `/refs/price_groups/${groupId}/promotion/`;

  return post(requestPath, {
    active: true,
  });
}

function disablePromotion({ groupId }) {
  const requestPath = `/refs/price_groups/${groupId}/promotion/`;

  return post(requestPath, {
    active: false,
  });
}

export {
  getDeviceSyncStatus,
  getPromotionInfo,
  updatePromotion,
  enablePromotion,
  disablePromotion,
  getPromotions,
};
