import React from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';
import { Provider, inject, observer } from 'mobx-react';
import { Button, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import Editor from 'elements/editor';
import Card from 'elements/card';
import Loader from 'elements/loader';
import Typography from 'elements/typography';

import { ElementHeader } from 'elements/headers';

const isContains = (menu, action) =>
  Array.isArray(menu) &&
  menu.findIndex((v) => v === action || isContains(v, action)) >= 0;

function ViewerPage({
  additionalActions,
  menu,
  widget: Overview,
  storage,
  match,
  history,
  allLinkText,
  isNotEditable,
  isModalEditing,
}) {
  if (!storage.isLoaded) {
    return <Loader size="large" />;
  }

  const {
    params: { id },
  } = match;
  const elementForEdit = storage.get(parseInt(id, 10));
  const path = match.url.split('/').slice(0, 3).join('/');

  if (!elementForEdit) {
    return <Redirect to={`${path}/../`} />;
  }

  const AdditionalActions = additionalActions || (() => null);
  const Header = withRouter(
    ({
      match: {
        params: { action },
      },
    }) => {
      const [isEditingModalShown, setIsEditingModalShown] =
        React.useState(false);
      const isEditing = action === 'edit';

      const handleEditPress = React.useCallback(() => {
        if (isModalEditing) {
          setIsEditingModalShown(true);
        } else {
          history.push(`${path}/edit`);
        }
      }, [isModalEditing, setIsEditingModalShown]);

      const handleModalCancel = React.useCallback(() => {
        setIsEditingModalShown(false);
      }, [setIsEditingModalShown]);

      return (
        <ElementHeader allLinkText={allLinkText} menu={menu}>
          <Space>
            <Typography.Title level={1}>{elementForEdit.name}</Typography.Title>
            {!isEditing && !isNotEditable && (
              <Button
                onClick={handleEditPress}
                icon={<EditOutlined />}
                type="text"
              />
            )}
            {isEditingModalShown && (
              <Editor
                isModal
                defaultEditingEnabled
                onCancel={handleModalCancel}
                data={elementForEdit}
              />
            )}
          </Space>
          <AdditionalActions />
        </ElementHeader>
      );
    },
  );

  const overview = Overview ? (
    <Provider element={elementForEdit}>
      <Header />
      <Overview />
    </Provider>
  ) : (
    <Redirect to={`${path}/view`} />
  );

  return (
    <Provider element={elementForEdit}>
      <Switch>
        <Route
          path={`${path}/:action`}
          render={({
            match: {
              params: { action },
            },
          }) => {
            if (
              !isContains(
                menu.map(({ path: p }) => p),
                action,
              )
            ) {
              return <Redirect to={`${path}${Overview ? '' : 'view'}`} />;
            }
            if (action === 'view' || action === 'edit') {
              return (
                <>
                  <Header />
                  <Card>
                    <Editor data={elementForEdit} />
                  </Card>
                </>
              );
            }
            return overview;
          }}
        />
        <Route>{overview}</Route>
      </Switch>
    </Provider>
  );
}

export default withRouter(inject('storage')(observer(ViewerPage)));
