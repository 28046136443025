/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Modal, Button, Upload, message, Space, Row } from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { apiUrl } from 'config';
import { request } from 'utils/request';
import { useUploaderProps } from 'utils/uploader';

function FilePreview({ name, onClickRemove }) {
  return (
    <Space>
      <FileExcelOutlined />
      <b>{name}</b>
      <Button icon={<DeleteOutlined />} onClick={onClickRemove} />
    </Space>
  );
}

function FileUploadErrorMessage({ text }) {
  return <span style={{ color: 'red' }}>{text}</span>;
}

function FileUploadTemplateString({ onPressTempalte }) {
  return (
    <span>
      Файл должен соответствовать{' '}
      <Button type="link" style={{ padding: 0 }} onClick={onPressTempalte}>
        шаблону
      </Button>
    </span>
  );
}

function downloadPriceGroupTemplate(priceGroupId) {
  return request
    .get(`/refs/price_groups/${priceGroupId}/template/`, {
      responseType: 'blob',
    })
    .then((response) => {
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `prices_${priceGroupId}_${Date.now()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}

export function PricesImportModal({
  onClose,
  open,
  priceGroupId,
  onImportSuccess,
}) {
  const [isError, setIsError] = useState(false);
  const {
    isUploading,
    uploadProps,
    choosenFile,
    removeFile,
    uploadSelectedFile,
  } = useUploaderProps({
    action: `${apiUrl}/refs/price_groups/${priceGroupId}/template/`,
    acceptList: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    onSuccess: () => {
      message.success('Данные успешно обновлены');
      onImportSuccess();
    },
    onError: () => {
      setIsError(true);
    },
    onPickFile: () => {
      setIsError(false);
    },
  });

  const handleClose = () => {
    setIsError(false);
    onClose();
  };

  const footer = (
    <Row justify="space-between">
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />} disabled={isUploading}>
          Выбрать файл
        </Button>
      </Upload>
      <Button
        type="primary"
        disabled={!choosenFile}
        loading={isUploading}
        onClick={uploadSelectedFile}
      >
        Импортировать
      </Button>
    </Row>
  );

  const content = (
    <Space direction="vertical">
      <FileUploadTemplateString
        onPressTempalte={() => downloadPriceGroupTemplate(priceGroupId)}
      />

      {choosenFile && (
        <FilePreview name={choosenFile.name} onClickRemove={removeFile} />
      )}

      {isError && (
        <FileUploadErrorMessage text="В файле нет данных или они не распознаются. Проверьте данные и попробуйте прикрепить еще раз" />
      )}
    </Space>
  );

  return (
    <Modal
      title="Выберите файл для импорта цен"
      footer={footer}
      open={open}
      onCancel={handleClose}
      onClose={handleClose}
    >
      {content}
    </Modal>
  );
}
