import { message } from 'antd';

function tryGetMessageFromError(error) {
  return error?.response?.data?.message || error?.response?.data?.detail;
}

/**
 *
 * @param {*} promise
 * @param {{loadingText, successText?, errorText?}} config
 */
export function promiseSequenceMessage(promise, config) {
  message.open({
    type: 'loading',
    content: config.loadingText,
    duration: 0,
  });

  return promise
    .then(() => {
      message.destroy();
      message.open({
        type: 'success',
        content: config.successText,
      });
    })
    .catch((err) => {
      message.destroy();
      message.open({
        type: 'error',
        content:
          config.errorText ||
          tryGetMessageFromError(err) ||
          'Произошла неизвестная ошибка',
      });
    });
}
