/* eslint class-methods-use-this: off */
import React from 'react';

import { computed } from 'mobx';
import Table from 'models/table';
import Filter from 'models/filters';

import { devices as devicesRout, salePoints as salePointsRout } from 'routes';
import { tableItemLink, tagsCell } from 'elements/table/trickyCells';
import {
  getDevices,
  getStats,
  getSalesChart,
  applyDevice,
  getVoltage,
  getWaterQuality,
  getQR,
  getUncleaned,
  getDisabled,
  getCleaningsCount,
} from 'services/device';
import DeviceStatus from 'elements/deviceStatus';

const COLUMNS = {
  id: {
    isVisibleByDefault: true,
    title: 'ID',
    width: 70,
    sortDirections: 'both',
  },
  name: {
    isDefaultSort: true,
    isVisibleByDefault: true,
    title: 'Название',
    grow: 3,
    sortDirections: 'both',
    transform: (_, datum, width) =>
      tableItemLink(datum.name, `${devicesRout.path}/${datum.id}`, width),
  },
  isOn: {
    isVisibleByDefault: true,
    title: 'Статус контроллера',
    width: 132,
    sortDirections: 'both',
    transform: (_, datum, width) => <DeviceStatus isOn={datum.isOn} />,
  },
  salePointName: {
    isVisibleByDefault: true,
    title: 'Объект',
    grow: 3,
    transform: (_, datum, width) =>
      tableItemLink(
        datum.salePointName,
        `${salePointsRout.path}/${datum.salePointId}`,
        width,
      ),
  },
  tags: {
    isVisibleByDefault: true,
    title: 'Теги',
    grow: 4,
    transform: tagsCell,
  },
  companyName: {
    isVisibleByDefault: true,
    title: 'Компания',
    grow: 2,
    sortDirections: 'both',
  },
  controller: {
    isVisibleByDefault: true,
    title: 'ID контроллера',
    grow: 3,
    sortDirections: 'both',
  },
  serial: {
    isVisibleByDefault: false,
    title: 'Серийный номер',
    grow: 3,
    sortDirections: 'both',
  },
  timeZone: {
    isVisibleByDefault: false,
    title: 'Временная зона',
    grow: 3,
    sortDirections: 'both',
  },
  mileage: {
    isVisibleByDefault: true,
    title: 'Пробег',
    grow: 3,
    sortDirections: 'both',
  },
  deviceModelType: {
    isVisibleByDefault: false,
    title: 'Тип оборудования',
    grow: 3,
    sortDirections: 'both',
  },
  deviceModelName: {
    isVisibleByDefault: true,
    title: 'Модель оборудования',
    grow: 3,
    sortDirections: 'both',
  },
  priceGroupName: {
    isVisibleByDefault: true,
    title: 'Группа цен',
    grow: 3,
    sortDirections: 'both',
  },
  setupDate: {
    isVisibleByDefault: false,
    title: 'Дата монтажа',
    width: 189,
    sortDirections: 'both',
  },
};

const declareFilters = (session) => ({
  companyId: {
    type: 'selector',
    title: 'Компания',
    apply: (general, data) => general(data.companyId),
    selector: () => session.companies.selector,
  },
  salePointId: {
    type: 'salepoints',
    apply: (general, data) => general(data.salePointId),
  },
  deviceModelType: {
    type: 'selector',
    title: 'Тип оборудования',
    apply: (general, data) => general(data.deviceModelId),
    selector: () => [],
    disabled: true,
  },
  deviceModelName: {
    type: 'selector',
    title: 'Модель оборудования',
    apply: (general, data) => general(data.deviceModelId),
    selector: () => session.deviceModels.selector,
  },
  tag: {
    type: 'tag',
    title: 'Тег',
    apply: (general, data) => {
      for (const tagId of data.tags) {
        if (general(tagId)) {
          return true;
        }
      }
      return false;
    },
    selector: () => [],
  },
  serial: {
    type: 'text',
    title: 'Серийный номер',
    apply: (general, { serial }) => serial !== null && general(serial),
  },
  setupDate: {
    type: 'daterange',
    title: 'Дата монтажа',
    apply: (general, data) => general(data.setupDate),
  },
  isNeedTechService: {
    type: 'checkbox',
    title: 'Требуется тех. обслуживание',
    apply: (_, data) => data.isNeedTechService,
    passiveValue: false,
  },
  isOn: {
    type: 'checkbox',
    title: 'Только контроллеры offline',
    apply: (_, data) => !data.isOn,
    passiveValue: false,
  },
});

class Devices extends Table {
  get isImpossibleToBeAsync() {
    return true;
  }

  constructor(session) {
    const filter = new Filter(declareFilters(session));
    super(COLUMNS, getDevices(session), filter, true);
  }

  toString() {
    return 'devices';
  }

  @computed get selector() {
    if (!this.isLoaded) {
      return undefined;
    }
    return this.rawData.map(({ id, name }) => [id, name]);
  }

  salePointsSelector(points) {
    const pointsSet = new Set(points);
    return this.rawData
      .filter(
        pointsSet.size === 0
          ? () => true
          : ({ salePointId }) => pointsSet.has(salePointId),
      )
      .map(({ id, name }) => [id, name]);
  }

  get(deviceId) {
    return this.rawData.find(({ id }) => id === deviceId);
  }

  getByPriceGroupId(groupId) {
    if (!this.isLoaded) {
      return undefined;
    }
    return this.rawData.filter(({ priceGroupId }) => priceGroupId === groupId);
  }

  getBySet(ids) {
    if (!this.isLoaded) {
      return undefined;
    }
    return this.rawData.filter(({ id }) => ids.has(id));
  }

  getPointsSetDevices(pointsSet) {
    if (!this.isLoaded) {
      return undefined;
    }
    return this.rawData.filter(
      ({ salePointId, isInactive }) =>
        pointsSet.has(salePointId) && !isInactive,
    );
  }

  getPointDevices(pointId) {
    if (!this.isLoaded) {
      return undefined;
    }
    return this.rawData.filter(
      ({ salePointId, isInactive }) => pointId === salePointId && !isInactive,
    );
  }

  count(predicate) {
    return this.rawData.filter(predicate).length;
  }

  getQR = getQR;

  getVoltage = getVoltage;

  getWaterQuality = getWaterQuality;

  getStats = getStats;

  getSalesChart = getSalesChart;

  getCleaningsCount = getCleaningsCount;

  applyDevice = applyDevice;

  getUncleaned = getUncleaned;

  getDisabled = getDisabled;
}

export default Devices;

export { declareFilters as DECLARE_DEVICE_FILTERS };
