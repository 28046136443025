/* eslint class-methods-use-this: off */
import React from 'react';
import { observable, action, autorun, when } from 'mobx';
import { Button, message } from 'antd';

import Table from 'models/table';
import RelatedFilters from 'models/relatedFilters'
import ExporterToEmail from 'models/exporterToEmail';
import LocalExporter from 'models/exporter';
import {
  beverage as beverageRout,
  devices as devicesRout,
  salePoints as salePointsRout,
} from 'routes';
import { daterangeToArgs } from 'utils/date';
import {
  OperationIcon,
  canceledIcon,
  indicatorsIcon,
} from 'elements/beverageIcons';
import {
  getBeverages,
  sendBeveragesReport,
  deleteBeverage,
  exportBeverages,
} from 'services/beverage';
import { tableItemLink } from 'elements/table/trickyCells';
import { sequentialGet } from 'utils/request';
import plural from 'utils/plural';

const BEVERAGE_DELETE_MESSAGE_KEY = 'BEVERAGE_DELETE_MESSAGE_KEY';

const declareColumns = (session) => ({
  id: {
    isVisibleByDefault: true,
    title: 'ID',
    width: 110,
    isAsyncorder: true,
  },
  cid: {
    isVisibleByDefault: false,
    title: 'Код',
    align: 'right',
    width: 70,
  },
  deviceDate: {
    isVisibleByDefault: true,
    title: 'Время налива',
    isDefaultSort: true,
    width: 189,
    sortDirections: 'both',
  },
  createdDate: {
    isVisibleByDefault: false,
    title: 'Время получения данных на сервер',
    width: 189,
    sortDirections: 'both',
  },
  deviceName: {
    isVisibleByDefault: true,
    title: 'Оборудование',
    grow: 1.5,
    sortDirections: 'both',
    transform: (v, datum, width) =>
      tableItemLink(v, `${devicesRout.path}/${datum.deviceId}`, width),
  },
  salePointName: {
    isVisibleByDefault: true,
    title: 'Объект',
    grow: 1.5,
    sortDirections: 'both',
    transform: (v, datum, width) =>
      tableItemLink(v, `${salePointsRout.path}/${datum.salePointId}`, width),
  },
  drinkName: {
    isVisibleByDefault: true,
    title: 'Напиток',
    grow: 1,
    sortDirections: 'both',
  },
  operationId: {
    isVisibleByDefault: true,
    title: 'Тип оплаты',
    width: 98,
    sortDirections: 'both',
    transform: (operationId, datum) => (
      <OperationIcon id={operationId} description={datum.operationName} />
    ),
  },
  saleSum: {
    isVisibleByDefault: true,
    title: 'Стоимость',
    align: 'right',
    width: 120,
    sortDirections: 'both',
    suffix: '₽',
  },
  canceled: {
    isVisibleByDefault: true,
    title: 'Отменен',
    width: 94,
    transform: (v) => (v ? canceledIcon : ''),
  },
  indicators: {
    isVisibleByDefault: true,
    title: 'Показатели',
    width: 100,
    transform: (indicators, datum) => (
      <Button
        type="link"
        style={{ padding: 0, height: 'auto' }}
        onClick={() => {
          session.beverages.setIndicatorBeverage(datum);
        }}
      >
        {indicatorsIcon}
      </Button>
    ),
  },
});

const declareFilters = (session) => ({
  device_date: {
    type: 'daterange',
    title: 'Время налива',
    apply: (general, data) => general(data.deviceDate),
  },
  device__sale_point__company__id: {
    type: 'selector',
    title: 'Компания',
    apply: (general, data) => general(data.companyId),
    selector: () => session.companies.selector,
  },
  device__sale_point__id: {
    type: 'salepoints',
    title: 'Объект',
    apply: (general, data) => general(data.salePointId),
    selector: () => session.points.selector,
  },
  device__id: {
    type: 'selector',
    title: 'Оборудование',
    apply: (general, data) => general(data.deviceId),
    selector: (filter) =>
      session.devices.salePointsSelector(filter.data.get('sale_point__id')),
  },
  drink__id: {
    type: 'selector',
    title: 'Напиток',
    apply: (general, data) => general(data.drinkId),
    selector: () => session.drinks.selector,
  },
  operation__id: {
    type: 'selector',
    title: 'Тип оплаты',
    apply: (general, data) => general(data.sale_sum),
    selector: () => session.beverageOperations.selector,
  },
  canceled: {
    type: 'checkbox',
    title: 'Отмененные напитки',
    apply: (_, data) => data.canceled,
    passiveValue: false,
  },
});

class Beverages extends Table {
  chart = null;

  exporter = null;

  localExporter = null;

  @observable
  beverageIndicators = null;

  session;

  constructor(session) {
    super(
      declareColumns(session),
      getBeverages(session, sequentialGet()),
      new RelatedFilters(declareFilters(session), session),
    );
    this.companiesFilters = [];
    this.session = session;
    this.filter.isShowSearch = false;

    this.exporter = new ExporterToEmail(sendBeveragesReport, this.filter, {
      checkDisable: () => !this.filter.data.has('device_date'),
      generateConfirmMessage: () =>
        'Ссылка будет отправлена на указанную почту, файл храниться 30 дней.',
    });

    autorun(() => {
      this.exporter.onChangeEmail(session?.user?.email || '');
    });

    this.localExporter = new LocalExporter(exportBeverages, this.filter, {
      checkDisable: () =>
        !this.filter.data.has('device_date') || this.data.length === 0,
      generateFilename: () => {
        const dateFormat = 'DD.MM-YYYY';
        const dateRange = this.filter.data.get('device_date');
        const dateStart = dateRange[0].format(dateFormat);
        const dateEnd = dateRange[1].format(dateFormat);

        return `Наливы_${dateStart}-${dateEnd}`;
      },
      generateConfirmMessage: () => {
        const dateFormat = 'DD.MM.YYYY HH:mm';
        const count = this.data.length;
        const dateRange = this.filter.data.get('device_date');
        const dateStart = dateRange[0].format(dateFormat);
        const dateEnd = dateRange[1].format(dateFormat);

        return `Выгрузить ${count} ${plural(count, [
          'запись',
          'записи',
          'записей',
        ])} по наливам с ${dateStart} по ${dateEnd}?`;
      },
    });

    when(() => session.permissions.isAllowDelete('beverages')).then(() => {
      this.actions = {
        onDelete: ({ id: idToRemove }) => {
          message.loading({
            content: 'Идет удаление налива..',
            duration: 0,
            key: BEVERAGE_DELETE_MESSAGE_KEY,
          });
          deleteBeverage(idToRemove)
            .then(() => {
              message.success({
                content: 'Налив успешно удален!',
                duration: 2,
                key: BEVERAGE_DELETE_MESSAGE_KEY,
              });
              this.rawData.replace(
                this.rawData.filter(({ id }) => id !== idToRemove),
              );
            })
            .catch((err) => {
              message.error({
                content: `Произошла ошибка при удалении налива: ${err}`,
                duration: 2,
                key: BEVERAGE_DELETE_MESSAGE_KEY,
              });
            });
        },
        isVisible: true,
      };
    });
  }

  setIndicatorBeverage(beverage) {
    this.beverageIndicators = beverage;
    this.beverageIndicators.fetchIndicators();
  }

  @action.bound
  unsetIndicatorBeverage() {
    this.beverageIndicators = null;
  }

  toString() {
    return 'Beverages';
  }

  getPathForDevice(deviceId) {
    return `${beverageRout.path}/?device__id__in=${deviceId}`;
  }

  getBeveragesForDevice(deviceId, limit, daterange) {
    const rangeArg =
      typeof daterange === 'undefined'
        ? ''
        : daterangeToArgs(daterange, 'device_date');
    return getBeverages(this.session)(
      limit,
      0,
      `device__id__in=${deviceId}${rangeArg}`,
    );
  }
}

export default Beverages;

export { declareFilters as DECLARE_BEVERAGES_FILTERS };
