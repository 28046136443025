import React from 'react';

import GenericPage from 'elements/genericPage';

import { DeviceOverview, DeviceTitleAction } from 'components/device';

function Devices() {
  return (
    <GenericPage
      storageName="devices"
      tableTitle="Всё оборудование"
      overview={DeviceOverview}
      overviewActions={DeviceTitleAction}
      allLinkText="Всё оборудование"
      overviewSubmenu={[
        {
          path: ['', 'calendar'],
          text: 'Техническая информация',
        },
        {
          path: 'commerce',
          text: 'Комммерческая информация',
        },
        {
          path: ['view', 'edit'],
          text: 'Справочная информация',
        },
        {
          path: 'voltage',
          text: 'Статистика напряжения',
        },
      ]}
    />
  );
}

export default Devices;
