import React from 'react';
import { Menu } from 'antd';

import Item from './item';

const { SubMenu } = Menu;

function Submenu({ items, children, icon, ...other }) {
  return (
    <SubMenu
      key={children}
      title={
        <span>
          {icon}
          <span>{children}</span>
        </span>
      }
      {...other}
    >
      {items.map(({ act, text }) => (
        <Item key={text} act={act} title={text}>
          {text}
        </Item>
      ))}
    </SubMenu>
  );
}

export default Submenu;
