import { Typography } from 'antd';

import Caption from './caption';
import Value from './value';
import Link from './link';

const { Title, Paragraph, Text } = Typography;

const CustomTypography = {
  Title,
  Text,
  Value,
  Paragraph,
  Link,
  Caption,
};

export default CustomTypography;
