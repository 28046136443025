import React from 'react';

import Chart from './chart';
import Clearance from '../clearance';
import Statistic from './statistic';

import style from './index.module.scss';

function Commerce() {
  return (
    <div className={style.root}>
      <Chart />
      <Statistic />
      <Clearance />
    </div>
  );
}

export default Commerce;
