import { action, computed, observable } from 'mobx';
import moment from 'moment';

import Datum from 'models/datum';

import {
  getPromotionInfo,
  updatePromotion,
  enablePromotion,
  disablePromotion,
} from 'services/promotion';
import { createNewPrice, getPriceGroup, transform } from 'services/price';
import { Promotion } from './promotion';

class PriceGroup extends Datum {
  @observable name = null;

  @observable companyId;

  @observable conceptionId = null;

  @observable systemKey;

  @observable devicesIdSet = observable.set();

  @observable pricesIdSet = observable.set();

  @observable isPromotionPrice = false;

  @observable id = null;

  @observable selectedSync = new Set();

  @observable lastUpdate = moment();

  @observable decimalPlaces = 0;

  @observable localPromotion = new Promotion(this.session, {});

  session;

  @computed get promotion() {
    return (
      this.session.promotions.promotionMap.get(this.id) || this.localPromotion
    );
  }

  constructor(session) {
    super(session.priceGroups.update);

    this.session = session;
  }

  get dump() {
    return {
      id: this.id,
      name: this.name,
      companyId: this.companyId,
      conceptionId: this.conceptionId,
      systemKey: this.systemKey,
      decimalPlaces: this.decimalPlaces,
      devicesIdSet: new Set([...this.devicesIdSet.values()]),
      pricesIdSet: new Set([...this.pricesIdSet.values()]),
    };
  }

  @computed get isSynchronized() {
    return this.devicesIdSet.size === 0;
  }

  @computed get devices() {
    return this.session.devices.getBySet(this.devicesIdSet);
  }

  @computed get company() {
    return this.session.companies.get(this.companyId);
  }

  @computed get currency() {
    return this.company?.currency;
  }

  @computed get companyName() {
    return this.company?.name;
  }

  @computed get prices() {
    return (
      this.session.prices
        .getBySet(this.pricesIdSet)
        ?.filter((price) => !price.promotionId) || []
    );
  }

  @computed get promotionPrices() {
    return (
      this.session.prices
        .getBySet(this.pricesIdSet)
        ?.filter((price) => price.promotionId) || []
    );
  }

  @computed get drinks() {
    return this.prices?.map((price) => price.drink);
  }

  @computed get drinksCount() {
    return this.prices.length;
  }

  @action setDecimalPlaces(decimalPlaces) {
    this.decimalPlaces = decimalPlaces;
    return this.update(this.dump);
  }

  @action addPrices(aditionlDrinks) {
    return this.update(this.dump, aditionlDrinks, this.session);
  }

  @action addDevices(devices) {
    const { dump } = this;
    for (const device of devices) {
      dump.devicesIdSet.add(device);
    }
    return this.update(dump);
  }

  @action removePrice(priceId) {
    return this.session.prices.remove(priceId).then(() => {
      this.pricesIdSet.delete(priceId);
    });
  }

  @action removeDevice(device) {
    const { dump } = this;
    dump.devicesIdSet.delete(device);
    return this.update(dump);
  }

  @action synchronize() {
    return this.session.priceGroups
      .synchronize(this.id, this.selectedSync)
      .then(() => {
        this.selectedSync = new Set();
      })
      .then(() => {
        this.updateDevicesSyncStatus();
      });
  }

  @computed get conception() {
    return this.session.сonceptions.get(this.conceptionId);
  }

  @computed get conceptionName() {
    return this.conception ? this.conception.name : null;
  }

  @computed get conceptionExtPLU() {
    return this.conception ? this.conception.extPLU : null;
  }

  @computed get editable() {
    // Если группа цен уже существует, то можно менять только название
    if (this.id) {
      return {
        name: {
          type: 'text',
          isRequired: true,
        },
      };
    }

    return {
      name: {
        type: 'text',
        isRequired: true,
      },
      companyId: {
        type: 'selector',
        selector: this.session.companies.selector,
        isRequired: true,
      },
      conceptionId: {
        type: 'selector',
        selector: this.session.сonceptions.selector,
      },
    };
  }

  @computed get values() {
    return [
      {
        dataIndex: 'name',
        title: 'Название',
        value: this.name,
      },
      {
        dataIndex: 'companyId',
        title: 'Компания',
        value: this.companyName,
      },
      {
        dataIndex: 'conceptionId',
        title: 'Концепция',
        value: this.conceptionName,
      },
    ];
  }

  // Promotion Methods
  async handlePriceImportSuccess() {
    await this.session.prices.fetchPrices();
    await this.fetchAndUpdate();
  }

  updateDevicesSyncStatus() {
    return Promise.all(
      this.devices?.map((device) => device.updateSyncStatus()) || [],
    );
  }

  async fetchPromotionInfo() {
    const { startDate, endDate, active, id, allday } = await getPromotionInfo({
      groupId: this.id,
    });

    if (!this.promotion) {
      this.promotion = new Promotion(this.session, {
        id,
        allday,
        startDate,
        endDate,
        active,
      });
    } else {
      this.promotion.update({
        id,
        allday,
        startDate,
        endDate,
        active,
      });
    }
  }

  async updatePromotion({ startDate, endDate, id, allday = false }) {
    await updatePromotion({
      id, // promotion id
      allday,
      groupId: this.id,
      startDate,
      endDate,
    });
    await this.fetchPromotionInfo();
  }

  async enablePromotion() {
    await enablePromotion({
      groupId: this.id,
    });
    await this.fetchPromotionInfo();

    setTimeout(() => {
      this.updateDevicesSyncStatus();
    }, 10 * 1000);

    this.selectedSync.clear();
  }

  async disablePromotion() {
    await disablePromotion({
      groupId: this.id,
    });
    await this.fetchPromotionInfo();

    setTimeout(() => {
      this.updateDevicesSyncStatus();
    }, 10 * 1000);
  }

  async addPomotionPrice({ drinkId, value }) {
    const { id } = await createNewPrice(
      {
        drinkId,
        promotionId: this.promotion.id,
        groupId: this.id,
        value,
      },
      this.session,
    );

    this.pricesIdSet.add(id);
  }

  @computed get isActivePromotion() {
    return Boolean(
      this.promotion
        ? this.promotion?.active
        : this.session.promotions.promotionMap.get(this.id)?.active,
    );
  }

  @computed get isPromotionPricesReady() {
    return (
      this.session.prices.getPromotionPricesByGroupId(this.id).length ===
      this.drinksCount
    );
  }

  @computed get isPromotionSettingsReady() {
    const promotionPricesDrinks = new Set(
      this.promotionPrices?.map(({ drinkId }) => drinkId),
    );
    // Если у нас установлена акционная цена для каждого напитка в группе
    const isAllPromotionPricesAreSet = this.prices?.every(({ drinkId }) =>
      promotionPricesDrinks.has(drinkId),
    );

    return Boolean(
      this.promotion &&
        this.promotion.startDate &&
        this.promotion.endDate &&
        this.devicesIdSet.size > 0 &&
        this.prices.length > 0 &&
        isAllPromotionPricesAreSet &&
        (this.promotion.allday
          ? moment().isSameOrBefore(this.promotion.endDate, 'day')
          : moment().isSameOrBefore(this.promotion.endDate)),
    );
  }
  // Promotion Methods END

  fetchAndUpdate() {
    return getPriceGroup(this.id).then((result) => {
      transform(result, this);
    });
  }
}

export default PriceGroup;
