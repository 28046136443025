import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Format from 'elements/format';
import Loader from 'elements/loader';
import Badge from 'elements/badged';

import tableWidget from '../tableWidget';

const FavoriteObjects = tableWidget([
  {
    title: 'Название объекта',
    dataIndex: 'salePoint',
    render: (point) =>
      point ? (
        <Badge size={8}>
          <Link to={point.path}>
            <Format>{point.name}</Format>
          </Link>
        </Badge>
      ) : (
        <Loader />
      ),
    sorter: (a, b) => {
      if (a.salePoint && b.salePoint) {
        return (
          a.salePoint.name.localeCompare(b.salePoint.name) || a.key - b.key
        );
      }
      if (b.salePoint) {
        return -1;
      }
      if (a.salePoint) {
        return 1;
      }
      return a.key - b.key;
    },
  },
  {
    title: 'Оборудование',
    dataIndex: 'deviceName',
    render: (v) => (
      <Badge>
        <Format>{v}</Format>
      </Badge>
    ),
    sorter: (a, b) => a.deviceName.localeCompare(b.deviceName) || a.key - b.key,
  },
  {
    title: 'Контроллер offline',
    dataIndex: 'lastConnectedAt',
    render: (v) => (
      <Badge>
        с <Format>{moment(v).format('DD-MM-YYYY HH:mm')}</Format>
      </Badge>
    ),
    sorter: (a, b) =>
      new Date(a.lastConnectedAt).getTime() -
      new Date(b.lastConnectedAt).getTime(),
  },
]);

export default FavoriteObjects;
