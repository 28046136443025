import { observable } from "mobx";
import DataManager from "models/table/dataManager";

export default class DataManagerWithMap extends DataManager {
	@observable map = {}

	constructor(partialLoader){
		const partialLoaderWithMapSetter = async () => {
			const data = await partialLoader();
			data.results.forEach(el => {
				this.map[el.id] = el
			});
			return data
		}
		super(partialLoaderWithMapSetter)
	}

}