import React from 'react';
import { Card, Table } from 'antd';
import { inject, observer } from 'mobx-react';

import Loader from 'elements/loader';
import Typography from 'elements/typography';
import DeviceStatus from 'elements/deviceStatus';
import { tableItemLink } from 'elements/table/trickyCells';

import style from './devicesList.module.scss';

const COLUMNS = [
  {
    title: 'Статус контроллера',
    dataIndex: 'isOn',
    render: (name, data) => <DeviceStatus isOn={data.isOn} />,
  },
  {
    title: 'Название',
    dataIndex: 'name',
    render: (name, data) => tableItemLink(name, data.path),
  },
];

function DeviceList({
  element: {
    details: { devices, devicesPath },
  },
}) {
  return (
    <Card className={style.root}>
      <Typography.Title level={3}>Оборудование</Typography.Title>
      {(() => {
        if (!Array.isArray(devices)) {
          return <Loader />;
        }
        const data = devices.map(({ id, name, isOn }) => ({
          id,
          name,
          path: `${devicesPath}/${id}`,
          key: id,
          isOn,
        }));
        return <Table pagination={false} columns={COLUMNS} dataSource={data} />;
      })()}
    </Card>
  );
}

export default inject('element')(observer(DeviceList));
