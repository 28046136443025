export function createAwaiter() {
  const awaiter = {
    promise: null,
    resolve: null,
    reject: null,
  };

  awaiter.promise = new Promise((resolve, reject) => {
    awaiter.resolve = resolve;
    awaiter.reject = reject;
  });

  return awaiter;
}
