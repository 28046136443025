import React from 'react';
import { inject, observer } from 'mobx-react';

import SubPage from 'elements/subpage';
import Typography from 'elements/typography';

import NotificationsList from 'components/notifications/notificationsList';
import NotificationsDelayList from 'components/notifications/notificationsDelayList';

function Notifications({ session }) {
  return (
    <SubPage
      menu={[
        {
          path: '',
          text: 'Уведомления по объектам',
          widget: () => <NotificationsList />,
        },
        {
          path: 'alert_time',
          text: 'Время оповещения',
          widget: () => <NotificationsDelayList />,
          hidden: !session.permissions.checkPermission(
            'eventReferences',
            'view',
          ),
        },
      ]}
      title={
        <span>
          <Typography.Title level={1}>
            Персональные уведомления
          </Typography.Title>
          <Typography.Text style={{ color: 'red' }}>
            По техническим причинам часть уведомлений отсутствует в списке.{' '}
            <br />
            Если вы не смогли настроить уведомления, напишите нам:
            support@core10.tech
          </Typography.Text>
        </span>
      }
    />
  );
}

export default inject('session')(observer(Notifications));
