import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Input } from 'antd';

import SelectableTable from 'elements/table/selectableTable';
import Icon from 'elements/icon';

import classes from './pickers.module.scss';

function PricePicker({ session, element, onSelect }) {
  const skipSet = new Set(element.prices.map(({ drinkId }) => drinkId));
  const [searchText, setSearch] = useState('');
  const search = searchText.toLowerCase();

  const ds = session.drinks.isLoaded
    ? session.drinks.rawData
        .filter(
          ({ name, id, companyId }) =>
            !skipSet.has(id) &&
            name.toLowerCase().indexOf(search) >= 0 &&
            companyId === element.companyId,
        )
        .map(({ id, name, plu }) => ({
          key: id,
          name,
          plu,
        }))
    : undefined;

  return (
    <div>
      <div className={classes.inputs}>
        <Input
          placeholder="Поиск"
          allowClear
          prefix={<Icon name="search-outline" />}
          value={searchText}
          onChange={({ target }) => {
            setSearch(target.value);
          }}
        />
      </div>
      <SelectableTable
        isSelectAll
        className={classes.table}
        onSelect={onSelect}
        columns={[
          {
            dataIndex: 'name',
            title: 'Название',
            grow: 2,
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
              if (typeof a.name === 'string' && typeof b.name === 'string') {
                return a.name.localeCompare(b.name) || a.key - b.key;
              }
              return a.key - b.key;
            },
          },
          {
            dataIndex: 'plu',
            title: 'PLU',
            width: 100,
            sorter: (a, b) => b.plu - a.plu || a.key - b.key,
          },
        ]}
        dataSource={ds}
      />
    </div>
  );
}

export default inject('session', 'element')(observer(PricePicker));
