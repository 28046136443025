import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button, message, Modal, Checkbox, TimePicker, DatePicker } from 'antd';
import moment from 'moment';

import promotionSettingsStyle from './PromotionSettings.module.scss';

function disabledDate(current) {
  // Can not select days before today and today
  // Make moment function to disable all date before today
  return moment(current).isBefore(moment().startOf('day'));
}

const PromotionSettings = observer(({ promotion }) => {
  const { startDate, endDate, allday } = promotion;
  const dateIsSet = startDate && endDate;

  const handleFulldayChange = ({ target: { checked } }) => {
    if (checked) {
      promotion.setAllday();
    } else {
      promotion.unsetAllday();
    }
  };

  const handleDateChange = (dates, data) => {
    if (dates) {
      promotion.setStartDate(moment(data[0]));
      promotion.setEndDate(moment(data[1]));
    } else {
      promotion.setStartDate(undefined);
      promotion.setEndDate(undefined);
    }
  };

  const handleTimeChange = (moments, times) => {
    if (times) {
      if (times[0] === times[1]) {
        message.error(
          'Время заверешния акции должно отличаться от времени начала!',
        );
        return;
      }
      promotion.setStartTime(times[0]);
      promotion.setEndTime(times[1]);
    } else {
      promotion.setStartTime(undefined);
      promotion.setEndTime(undefined);
    }
  };

  return (
    <div>
      <p className={promotionSettingsStyle.description}>
        Напитки будут продаваться по акционным ценам в период действия, при
        условии, что акция активна.
      </p>
      <div className={promotionSettingsStyle.settingsContainer}>
        <div className={promotionSettingsStyle.settingsLeft}>
          <h4>Выберите период действия акции</h4>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            className={promotionSettingsStyle.datePicker}
            placeholder={['Дата начала', 'Дата завершения']}
            onChange={handleDateChange}
            value={[startDate, endDate]}
          />
        </div>
        <div className={promotionSettingsStyle.settingsRight}>
          <h4>Выберите интервал времени ({moment().format('Z')})</h4>
          <TimePicker.RangePicker
            className={promotionSettingsStyle.timePicker}
            placeholder={['Время начала', 'Время завершения']}
            value={[startDate, endDate]}
            minuteStep={10}
            format="HH:mm"
            disabled={allday || !dateIsSet}
            onChange={handleTimeChange}
          />
          <Checkbox
            disabled={!dateIsSet}
            className={promotionSettingsStyle.alldayCheckbox}
            checked={allday}
            onChange={handleFulldayChange}
          >
            24 часа
          </Checkbox>
        </div>
      </div>
    </div>
  );
});

export const PromotionSettingsAction = observer(({ group }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const isPromotionActive = group.isActivePromotion;
  const promotionForUpdate = useMemo(
    () => (open && group.promotion ? group.promotion.makeCopy() : null),
    [open],
  );

  if (!group.promotion) {
    return null;
  }

  const handleOk = () => {
    if (!promotionForUpdate.endDate || !promotionForUpdate.startDate) {
      message.error('Необходимо указать даты начала и завершения акции!');
      return;
    }

    group
      .updatePromotion(promotionForUpdate)
      .then(() => {
        message.success('Акция успешно сохранена!');
      })
      .catch((e) => {
        message.error('Не удалось сохранить акцию!');
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <>
      <Modal
        width={960}
        title="Настройка параметров акции"
        okText="Сохранить"
        cancelText="Отмена"
        visible={open}
        onCancel={handleClose}
        onOk={handleOk}
      >
        {promotionForUpdate && (
          <PromotionSettings promotion={promotionForUpdate} />
        )}
      </Modal>
      <Button disabled={isPromotionActive} onClick={handleOpen}>
        Настройка акции
      </Button>
    </>
  );
});
