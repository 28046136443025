import { useState } from 'react';
import { getAutherizationHeader } from 'utils/request';
import { createAwaiter } from 'utils/awaiter';

// Single file uploading UploadProps for antd Upload component
export function useUploaderProps({
  action,
  acceptList,
  onSuccess,
  onError,
  onProgress,
  onPickFile,
}) {
  const [awaiter, setAwaiter] = useState(null);
  const [choosenFile, setChoosenFile] = useState(null);
  const [isUploading, setIsIsUploading] = useState(false);

  const reset = () => {
    setIsIsUploading(false);
    setChoosenFile(null);
    setAwaiter(null);
    awaiter.reject();
  };

  const beforeUpload = (file) => {
    const localAwaiter = createAwaiter();

    setAwaiter(localAwaiter);
    setChoosenFile(file);
    onPickFile(file);

    return localAwaiter.promise;
  };

  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      reset();

      if (info.file.status === 'done') {
        onSuccess?.(info.file);
      } else if (info.file.status === 'error') {
        onError?.(info.file);
      }
    } else {
      onProgress?.(info.file);
    }
  };

  const uploadSelectedFile = () => {
    awaiter.resolve(choosenFile);
    setIsIsUploading(true);
  };

  const removeFile = () => {
    reset();
  };

  const uploadProps = {
    name: 'file',
    accept: acceptList.join(','),
    action,
    showUploadList: false,
    method: 'PUT',
    maxCount: 1,
    headers: {
      Authorization: getAutherizationHeader(),
    },
    beforeUpload,
    onChange,
  };

  return {
    choosenFile,
    isUploading,
    uploadProps,
    removeFile,
    uploadSelectedFile,
  };
}
