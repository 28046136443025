import { observable, action, computed } from 'mobx';

const defaultStartHour = 12;
const defaultEndHour = 23;
const defaultMinutes = 0;
const defaultAlldayHour = 0;
const defaultAlldayMinutes = 0;

export class Promotion {
  @observable id = undefined;

  @observable date = {
    start: undefined,
    end: undefined,
  };

  @observable active = false;

  @observable allday = false;

  constructor(session, { startDate, endDate, active, allday, id }) {
    this.date.start = startDate || undefined;
    this.date.end = endDate || undefined;
    this.active = active || false;
    this.allday = allday || false;
    this.id = id || undefined;

    this.session = session;
  }

  update({ startDate, endDate, active, allday, id }) {
    this.date.start = startDate || undefined;
    this.date.end = endDate || undefined;
    this.active = active || false;
    this.allday = allday || false;
    this.id = id || undefined;
  }

  @action setStartDate(date) {
    const dateExisted = Boolean(this.date.start);

    const hourFallback = this.allday ? defaultAlldayHour : defaultStartHour;
    const minuteFallback = this.allday ? defaultAlldayMinutes : defaultMinutes;

    const hour = dateExisted ? this.date.start.hour() : hourFallback;
    const minute = dateExisted ? this.date.start.minute() : minuteFallback;

    this.date.start = date.hour(hour).minute(minute).second(0).millisecond(0);
  }

  @action setEndDate(date) {
    const dateExisted = Boolean(this.date.end);

    const hourFallback = this.allday ? defaultAlldayHour : defaultEndHour;
    const minuteFallback = this.allday ? defaultAlldayMinutes : defaultMinutes;

    const hour = dateExisted ? this.date.end.hour() : hourFallback;
    const minute = dateExisted ? this.date.end.minute() : minuteFallback;

    this.date.end = date.hour(hour).minute(minute).second(0).millisecond(0);
  }

  @action setStartTime(time) {
    const [hours, minutes] = time.split(':');
    this.date.start = this.date.start.clone().hours(hours).minutes(minutes);
  }

  @action setEndTime(time) {
    const [hours, minutes] = time.split(':');
    this.date.end = this.date.end.clone().hours(hours).minutes(minutes);
  }

  @action setAllday() {
    this.allday = true;
    this.setStartTime(`${defaultAlldayHour}:${defaultAlldayMinutes}`);
    this.setEndTime(`${defaultAlldayHour}:${defaultAlldayMinutes}`);
  }

  @action unsetAllday() {
    this.allday = false;
    this.setStartTime(`${defaultStartHour}:${defaultMinutes}`);
    this.setEndTime(`${defaultEndHour}:${defaultMinutes}`);
  }

  @computed get startDate() {
    return this.date.start;
  }

  @computed get endDate() {
    return this.date.end;
  }

  makeCopy() {
    return new Promotion(this.session, {
      startDate: this.date.start?.clone(),
      endDate: this.date.end?.clone(),
      active: this.active,
      id: this.id,
      allday: this.allday,
    });
  }
}
