import { observable } from 'mobx';
import { getPromotions } from 'services/promotion';
import { Promotion } from './promotion';

export class Promotions {
  @observable promotionMap = new Map();

  constructor(session) {
    this.session = session;

    getPromotions().then((promotions) => {
      promotions.forEach((promotion) => {
        this.promotionMap.set(
          promotion.groupId,
          new Promotion(session, promotion),
        );
      });
    });
  }
}
