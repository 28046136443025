import React from 'react';

import GenericPage from 'elements/genericPage';

function Companies() {
  return (
    <GenericPage
      storageName="companies"
      tableTitle="Все компании"
      allLinkText="Все компании"
    />
  );
}

export default Companies;
