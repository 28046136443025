import React, { useState } from 'react';
import { Button, Input, message } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import Format from 'elements/format';
import Icon from 'elements/icon';

import classes from './priceCell.module.scss';

const numberFormatRegexp = /^(\d+)(\.\d{0,4})?$/;

export const priceTransformer = (value) => {
  const formatedValue = String(value).replace(/,/g, '.');
  if (numberFormatRegexp.test(formatedValue) || formatedValue === '') {
    return formatedValue;
  }

  return false;
};

export const renderPriceTextValue = (value) =>
  Number.isFinite(value) ? <Format isCost>{value}</Format> : null;

function CommitInputCell({ value, sendValue, transformer, renderTextValue }) {
  const [isWarn, setWarn] = useState(false);
  const [isEdditing, setEdditing] = useState(false);
  const [isSending, setSending] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const handleChangeValue = ({ target: { value: newValue } }) => {
    if (transformer) {
      const transformedValue = transformer(newValue);
      setCurrentValue(
        transformedValue === false ? currentValue : transformedValue,
      );
    } else {
      setCurrentValue(newValue);
    }
  };

  const commit = () => {
    if (currentValue !== value) {
      sendValue(currentValue)
        .then(() => {
          message.success('Успешно обновлено!');
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 1000);
        })
        .catch((error) => {
          message.error('Не удалось обновить');
          console.error(error);
          setWarn(true);
          setTimeout(() => {
            setWarn(false);
          }, 1000);
        })
        .finally(() => {
          setSending(false);
        });
      setSending(true);
    }
    setEdditing(false);
  };

  if (isEdditing) {
    return (
      <div
        className={classNames(classes.root, {
          [classes.warn]: isWarn,
          [classes.success]: isSuccess,
        })}
      >
        <div className={classes.inputs}>
          <Input
            autoFocus
            className={classes.input}
            value={currentValue}
            onChange={handleChangeValue}
          />
        </div>
        <div className={classes.actions}>
          <Button
            icon={<Icon name="paper-plane-outline" size={24} />}
            type="text"
            onClick={commit}
          />
          <Button
            icon={<Icon name="slash-outline" size={24} />}
            type="text"
            onClick={() => {
              setEdditing(false);
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      className={classNames(classes.root, {
        [classes.warn]: isWarn,
        [classes.success]: isSuccess,
      })}
    >
      <span>{renderTextValue ? renderTextValue(value) : value || '—'}</span>
      {isSending ? (
        <LoadingOutlined />
      ) : (
        <Button
          icon={<EditOutlined style={{ transform: 'scale(1.37)' }} />}
          type="text"
          onClick={() => {
            setEdditing(true);
          }}
        />
      )}
    </div>
  );
}

export default CommitInputCell;
