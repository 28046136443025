const CHART_COLORS = [
  '#228148',
  '#66C7F4',
  '#142288',
  '#99C2A2',
  '#F94144',
  '#f3722c',
  '#048ba8',
  '#577590',
  '#f9c74f',
  '#90be6d',
  '#43aa8b',
  '#4d908e',
  '#f9844a',
  '#99C2A2',
  '#277da1',
  '#54478c',
  '#2c699a',
  '#f8961e',
  '#0db39e',
  '#16db93',
];

export default CHART_COLORS;
