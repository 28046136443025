import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

function CustomLink({ children, to, ...props }) {
  if (to) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }

  return <Typography.Link {...props}>{children}</Typography.Link>;
}

CustomLink.propTypes = Text.propTypes;
CustomLink.defaultProps = Text.defaultProps;

export default CustomLink;
