import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, Tooltip } from 'antd';
import classNames from 'classnames';

import DeviceStatus from 'elements/deviceStatus';
import Format from 'elements/format';
import { EllipsisLoader } from 'elements/loader';
import Icon from 'elements/icon';

import List from './list';

import style from './priceGroupHeader.module.scss';
import deviceListStyles from './deviceList.module.scss';

function DevicesList({ element, onAdd, isLoading, selected, onSelect }) {
  const select =
    (id) =>
    ({ target: { checked } }) => {
      selected[checked ? 'add' : 'delete'](id);
      onSelect(new Set([...selected.values()]));
    };
  const toDataSource = (device) => ({
    isOn: device.isOn,
    key: device.id,
    name: device.name,
    syncDate: device.syncDate,
    syncStatus: device.syncStatus,
    salePointName: device.salePointName,
    isPromotion: device.isPromotionPrice,
    isActual: device.isActualPrice,
    rm: () => element.removeDevice(device.id),
    setSynk: {
      setSync: select(device.id),
      isSelected: selected.has(device.id),
    },
    className: classNames({
      'ant-table-row-selected': selected.has(device.id),
    }),
  });

  const onSelectAll = ({ target: { checked } }) => {
    onSelect(new Set(checked ? [...element.devices.map(({ id }) => id)] : []));
  };

  const COLUMNS = [
    {
      title: (
        <Checkbox
          disabled={element.isActivePromotion}
          indeterminate={
            selected.size && selected.size < element.devices?.length
          }
          checked={selected.size === element.devices?.length}
          onChange={onSelectAll}
        />
      ),
      width: 28,
      dataIndex: 'name',
      render: (value, { setSynk: { setSync, isSelected } }) => (
        <Checkbox
          disabled={element.isActivePromotion}
          onChange={setSync}
          checked={isSelected}
        />
      ),
    },
    {
      title: 'Контроллер',
      width: 100,
      dataIndex: 'isOn',
      key: 'isOn',
      render: (value) => <DeviceStatus isOn={value} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Объект',
      dataIndex: 'salePointName',
      render: (name) => <Format>{name}</Format>,
    },
    {
      title: 'Действующие цены',
      dataIndex: 'isPromotion',
      width: 200,
      render: (_, { isPromotion, isActual }) => {
        if (isPromotion === null) {
          return '—';
        }

        if (isPromotion) {
          return 'Акционные цены';
        }

        if (isActual) {
          return 'Стандартные цены';
        }

        return (
          <Tooltip title="С момента последней синхронизации цены были обновлены. Данные на устройстве могут быть не актуальны.">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Стандартные цены&nbsp;&nbsp;</span>
              <Icon
                className={deviceListStyles['warning-icon']}
                size={16}
                name="alert-triangle-outline"
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Дата синхронизации',
      dataIndex: 'syncDate',
      width: 200,
      render: (syncDate, { syncStatus }) =>
        syncDate && syncStatus !== 'pending' ? (
          <Format>{syncDate}</Format>
        ) : (
          <EllipsisLoader />
        ),
    },
    {
      title: 'Статус',
      dataIndex: 'syncStatus',
      width: 200,
      render: (syncStatus, { syncDate }) => {
        if (syncStatus === 'success') {
          return (
            <span className={classNames(style.sync, style.synced)}>
              Синхронизировано
            </span>
          );
        }

        if (syncStatus === 'pending') {
          return (
            <span className={classNames(style.sync)}>
              В процессе синхронизации
            </span>
          );
        }

        return (
          <span className={classNames([style.sync, style.notSynced])}>
            Не синхронизировано
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (!Array.isArray(element.devices)) return () => {};

    element.updateDevicesSyncStatus();
    const timerId = setInterval(() => {
      element.updateDevicesSyncStatus();
    }, 60 * 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [element.devices?.length]);

  return (
    <List
      isLoading={isLoading}
      dataSource={element.devices}
      toDataSource={toDataSource}
      columns={COLUMNS}
      onAdd={element.isActivePromotion ? undefined : onAdd}
      title={`Список оборудования (${element.devicesIdSet.size})`}
    />
  );
}

export default inject('element')(observer(DevicesList));
