import React from 'react';

function JsonDictTextFormat({ json }) {
  return (
    <div>
      {Object.keys(json)
        .sort()
        .map((key) => (
          <div>
            <b>{key}</b>
            {'  '}
            {json[key] || '-'}
          </div>
        ))}
    </div>
  );
}

export default JsonDictTextFormat;
