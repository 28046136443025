import React, { useState } from 'react';
import { Button, InputNumber, Space, message, Dropdown } from 'antd';

import Icon from 'elements/icon';
import Typography from 'elements/typography';

export function PriceDecimalPlacesAction({ group }) {
  const [localValue, setLocalValue] = useState(group.decimalPlaces);
  const save = () => {
    group
      .setDecimalPlaces(localValue)
      .then(() =>
        message.success(
          `Кол-во знаков после запятой для группы "${group.name}" успешно обновлено!`,
        ),
      );
  };
  const cancel = () => {
    setLocalValue(group.decimalPlaces);
  };
  const disabled = localValue === group.decimalPlaces;

  const renderDecimal = () => (
    <Space
      style={{
        background: 'white',
        padding: '8px 16px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        borderRadius: 8,
      }}
      direction="vertical"
      align="end"
    >
      <Space>
        <Typography.Text>Кол-во знаков после запятой</Typography.Text>
      </Space>
      <Space>
        <InputNumber
          onChange={setLocalValue}
          onPressEnter={save}
          value={localValue}
        />
        <Button onClick={save} disabled={disabled}>
          <Icon name="checkmark-outline" />
        </Button>
        <Button onClick={cancel} disabled={disabled}>
          <Icon name="close-outline" />
        </Button>
      </Space>
    </Space>
  );

  return (
    <Dropdown dropdownRender={renderDecimal} disabled={group.promotion?.active}>
      <Button icon={<Icon name="settings-2-outline" />} />
    </Dropdown>
  );
}
