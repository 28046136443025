import React from 'react';
import { withRouter, Redirect, Switch, Route } from 'react-router-dom';
import { inject, observer, Provider } from 'mobx-react';

import GenericTablePage from './tablePage';
import OverviewPage from './overviewPage';

const DEFAULT_SUBMENU = [
  {
    path: ['view', 'edit'],
    text: 'Справочная информация',
  },
];

function GenericPage({
  isHaveNotOverview,
  refreshInterval,
  storageName,
  tableTitle,
  overview,
  overviewActions,
  overviewSubmenu,
  additionalHeaderButtons,
  session,
  location,
  match: { path },
  allLinkText,
  isNotEditable,
  isModalEditing,
}) {
  console.assert(
    typeof storageName === 'string',
    `Не задан storage для ${path}`,
  );
  const storage = session[storageName];
  const { filter } = storage;
  const isNeedToRedirect = location.search.slice(1) !== filter.search;

  const submenu = Array.isArray(overviewSubmenu)
    ? overviewSubmenu
    : DEFAULT_SUBMENU;

  return (
    <Provider storage={storage} filter={filter}>
      <Switch>
        {!isHaveNotOverview && (
          <Route path={`${path}/:id`}>
            <OverviewPage
              isModalEditing={isModalEditing}
              isNotEditable={isNotEditable}
              additionalActions={overviewActions}
              menu={submenu}
              widget={overview}
              allLinkText={
                allLinkText ||
                `Все ${(tableTitle || 'элементы').toLocaleLowerCase()}`
              }
            />
          </Route>
        )}
        <Route>
          {isNeedToRedirect && <Redirect to={`${path}?${filter.search}`} />}
          <Provider table={storage}>
            <GenericTablePage
              additionalHeaderButtons={additionalHeaderButtons}
              refreshInterval={refreshInterval}
              title={tableTitle}
            />
          </Provider>
        </Route>
      </Switch>
    </Provider>
  );
}

export default withRouter(inject('session')(observer(GenericPage)));
