import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Modal, Space, Popconfirm } from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import PriceList from './priceList';
import PricePicker from './pricePicker';
import DeviceList from './devicesList';
import DevicePicker from './devicesPicker';

import style from './priceGroupHeader.module.scss';

const PricesSyncAction = inject('element')(
  observer(({ element }) => {
    const [isSynchronizing, setSynchronizing] = useState(false);
    const onClick = () => {
      setSynchronizing(true);
      element.synchronize().then(() => setSynchronizing(false));
    };
    const { isSynchronized, selectedSync, isActivePromotion } = element;
    const notLoadingIcon = isSynchronized ? (
      <CheckCircleOutlined />
    ) : (
      <ReloadOutlined />
    );
    const notLoadingMessage = isSynchronized
      ? 'Синхронизировано'
      : `Синхронизировать (${selectedSync.size})`;
    return (
      <Button
        disabled={
          isSynchronizing ||
          isSynchronized ||
          selectedSync.size === 0 ||
          isActivePromotion
        }
        icon={isSynchronizing ? <LoadingOutlined /> : notLoadingIcon}
        onClick={onClick}
      >
        {isSynchronizing ? 'Синхронизация...' : notLoadingMessage}
      </Button>
    );
  }),
);

const DevicesRemoveAction = inject('element')(
  observer(({ element }) => {
    const { selectedSync } = element;

    const onClick = async () => {
      for (const deviceId of selectedSync.values()) {
        // eslint-disable-next-line no-await-in-loop
        await element.removeDevice(deviceId);
      }

      selectedSync.clear();
    };

    return (
      <Popconfirm
        title="Вы уверены, что хотите удалить выбранные устройства из группы цен?"
        onConfirm={onClick}
        okText="Да"
        cancelText="Отменить"
        disabled={selectedSync.size === 0 || element.isActivePromotion}
      >
        <Button
          danger
          disabled={selectedSync.size === 0 || element.isActivePromotion}
        >
          Удалить оборудование ({selectedSync.size})
        </Button>
      </Popconfirm>
    );
  }),
);

function PriceGroupTitleAction() {
  return (
    <Space>
      <DevicesRemoveAction />
      <PricesSyncAction />
    </Space>
  );
}

@inject('element')
@observer
class PriceGroupOverview extends React.Component {
  state = { openedPicker: null, sendingData: null };

  selectedPrices = new Set();

  selectedDevices = new Set();

  setOpenedPicker = (openedPicker) => {
    this.setState({ openedPicker });
  };

  onCancel = () => this.setState({ openedPicker: null, sendingData: null });

  render() {
    const { element } = this.props;
    const { openedPicker, sendingData } = this.state;

    const MODALS = {
      device: {
        title: 'Оборудование',
        count: () => this.selectedDevices.size,
        commit: () => element.addDevices([...this.selectedDevices.values()]),
      },
      price: {
        title: 'Напитки',
        count: () => this.selectedPrices.size,
        commit: () => element.addPrices([...this.selectedPrices.values()]),
      },
    };

    const onOk = () => {
      this.setState({ sendingData: openedPicker, openedPicker: null });
      MODALS[openedPicker].commit().then(this.onCancel);
    };

    function MyModal({ children, mode, onCancel }) {
      return (
        <Modal
          title={MODALS[mode]?.title}
          open={openedPicker === mode}
          okText="Добавить"
          cancelText="Закрыть"
          onCancel={onCancel}
          onOk={onOk}
          width={800}
          transitionName=""
          maskTransitionName=""
        >
          {children}
        </Modal>
      );
    }

    const onSelectPrice = (data) => {
      this.selectedPrices = data;
    };

    const onSelectDevice = (data) => {
      this.selectedDevices = data;
    };

    const onSelectSync = (data) => {
      element.selectedSync = data;
    };

    return (
      <div className={style.root}>
        <MyModal mode="device" onCancel={this.onCancel}>
          <DevicePicker onSelect={onSelectDevice} />
        </MyModal>
        <MyModal mode="price" onCancel={this.onCancel}>
          <PricePicker onSelect={onSelectPrice} />
        </MyModal>
        <DeviceList
          isLoading={sendingData === 'device'}
          onAdd={() => this.setOpenedPicker('device')}
          onSelect={onSelectSync}
          selected={element.selectedSync}
        />
        <PriceList
          isLoading={sendingData === 'price'}
          onAdd={() => this.setOpenedPicker('price')}
        />
      </div>
    );
  }
}

export { PriceGroupOverview, PriceGroupTitleAction };
