import { action, observable } from 'mobx';
import { getSystemNotification } from 'services/systemNotification';

export default class SystemNotification {
  constructor() {
    getSystemNotification().then(({ type, text }) => {
      this.setNotificationData({ text, type });
    });
  }

  @observable type = 'normal';

  @observable text = '';

  @observable isShown = false;

  @action hide = () => {
    this.isShown = false;
  };

  @action setNotificationData = ({ text, type }) => {
    this.text = text || '';
    this.type = type || 'normal';
    this.isShown = Boolean(text);
  };
}
