import { get } from 'utils/request';

export async function getSystemNotification() {
  const url = '/data/announcements/';
  const data = await get(url);
  const [notification] = data;

  if (notification) {
    return {
      text: notification.text,
      type: notification.category,
    };
  }

  return {
    text: '',
  };
}
