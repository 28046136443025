import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';

import Icon from 'elements/icon';

import styles from './index.module.scss';

/**
 *
 * @param controllerStatus {lastConnectedAt, uptime, isOnline}
 * @returns
 */
function createLabelRowsFromControllerStatus(controllerStatus) {
  const rows = [];

  rows.push({
    icon: 'wifi-outline',
    title: controllerStatus?.isOnline ? 'В сети' : 'Не в сети',
    caption: 'Текущий статус',
  });

  if (controllerStatus.lastConnectedAt && !controllerStatus?.isOnline) {
    rows.push({
      icon: 'log-in-outline',
      title: moment(controllerStatus.lastConnectedAt).format(
        'DD-MM-YYYY HH:mm',
      ),
      caption: 'Дата последнего соединения',
    });
  }

  if (controllerStatus.uptime && controllerStatus?.isOnline) {
    rows.push({
      icon: 'clock-outline',
      title: formatDuration(controllerStatus.uptime),
      caption: 'Время работы',
    });
  }

  return rows;
}

const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

function formatDuration(seconds) {
  const hours = Math.floor(seconds / (60 * 60));
  const minutes = Math.floor((seconds / 60) % 60);
  const secondsLeft = Math.floor(seconds % 60);

  const hoursText = String(hours);
  const minutsText = String(minutes);
  const secondsText = String(secondsLeft);

  return [
    hours ? `${hoursText} ${declOfNum(hours, ['час', 'часа', 'часов'])}` : '',
    minutes
      ? `${minutsText} ${declOfNum(minutes, ['минута', 'минуты', 'минут'])}`
      : '',
    secondsLeft && !minutes && !hours
      ? `${secondsText} ${declOfNum(secondsLeft, [
          'секунда',
          'секунды',
          'секунд',
        ])}`
      : '',
  ]
    .filter((e) => Boolean(e))
    .join(' ');
}

export function ControllerStatusModal({ controllerStatus, visible, onCancel }) {
  const renderRow = (icon, title, caption) => (
    <div className={styles.row}>
      <div className={styles.icon}>
        <Icon name={icon} size={28} />
      </div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.caption}>{caption}</div>
      </div>
    </div>
  );
  const rows = createLabelRowsFromControllerStatus(controllerStatus);

  return (
    <Modal
      title="Статус контроллера"
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      <div>
        {rows.map((row) => renderRow(row.icon, row.title, row.caption))}
      </div>
    </Modal>
  );
}
