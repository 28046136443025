import React from 'react';
import { inject, Provider, observer } from 'mobx-react';

import IngredientsModel from 'models/comerce/ingredients';
import Table from 'elements/table';
import Card from 'elements/card';
import { TableHeader } from 'elements/headers';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getOddmentsDocument } from 'services/ingredients';

@inject('session')
@observer
class Ingredients extends React.Component {
  state = {
    model: null,
    areOddmentsLoading: false,
  };

  componentDidMount() {
    const { session } = this.props;
    this.setState({ model: new IngredientsModel(session) });
  }

  setAreOddmentsLoading(isLoading) {
    this.setState((prevState) => ({
      ...prevState,
      areOddmentsLoading: isLoading,
    }));
  }

  render() {
    const { model, areOddmentsLoading } = this.state;

    if (model === null) {
      return null;
    }

    const downloadOddments = async () => {
      if (!areOddmentsLoading) {
        this.setAreOddmentsLoading(true);
        try {
          const fileName = 'oddments_report';
          const fileBytes = await getOddmentsDocument();
          const fileUrl = URL.createObjectURL(fileBytes);
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = fileName;
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(fileUrl);
        } catch (e) {
          message.error('Не удалось получить остатки');
          console.error('downloadOddments', e);
        }

        this.setAreOddmentsLoading(false);
      }
    };

    const addButtons = (
      <Button
        onClick={downloadOddments}
        icon={<DownloadOutlined />}
        loading={areOddmentsLoading}
      >
        Скачать текущие остатки
      </Button>
    );

    return (
      <Provider table={model} filter={model.filter}>
        <TableHeader
          title="Расход ингредиентов"
          additionalButtons={addButtons}
        />
        <Card>
          <Table />
        </Card>
      </Provider>
    );
  }
}

export default Ingredients;
