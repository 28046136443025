import React from 'react';

import GenericPage from 'elements/genericPage';

import {
  PriceGroupOverview,
  PriceGroupTitleAction,
} from 'components/priceGroup';

function PriceLists() {
  return (
    <GenericPage
      storageName="priceGroups"
      tableTitle="Группы цен"
      allLinkText="Группы цен"
      overview={PriceGroupOverview}
      overviewActions={PriceGroupTitleAction}
      overviewSubmenu={[]}
      isModalEditing
    />
  );
}

export default PriceLists;
