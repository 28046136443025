import { observable, computed, action } from 'mobx';
import localStorage from 'mobx-localstorage';
import { BEARER_KEY } from 'utils/request';

import { login, me } from 'services/auth';

class Auth {
  @observable user = undefined;

  contacts = { email: 'support@core10.tech', phone: '88007003942' };

  constructor() {
    // contacts().then((data) => {
    //   this.contacts = data;
    // });
    if (localStorage.getItem(BEARER_KEY) === null) {
      this.logout();
    } else {
      me()
        .then((user) => {
          this.user = user;
        })
        .catch(() => {
          this.logout();
        });
    }
  }

  @computed get isAuthorized() {
    return typeof this.user === 'object' && this.user !== null;
  }

  @computed get isAuthChecked() {
    return typeof this.user === 'object';
  }

  @action logout() {
    this.user = null;
    localStorage.removeItem(BEARER_KEY);
  }

  // eslint-disable-next-line class-methods-use-this
  @action clear() {
    localStorage.clear();
    window.location.reload();
  }

  @action login(data) {
    return new Promise((resolve, reject) => {
      login(data)
        .then((token) => {
          localStorage.setItem(BEARER_KEY, token);
          me()
            .then((user) => {
              resolve(user);
              this.user = user;
            })
            .catch((err) => {
              console.error(err);
              reject(err);
              this.logout();
            });
        })
        .catch(reject);
    });
  }
}

export default Auth;
