import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Space } from 'antd';
import moment from 'moment';

import Loader from 'elements/loader';

import List from './list';
import { PricesImportModal } from './pricesImportModal';
import { DEFAULT_COLUMNS, PROMOTION_COLUMNS } from './priceListColumnsConfig';
import { ActivatePromotionAction } from './promotion/ActivatePromotionAction';
import { PromotionSettingsAction } from './promotion/PromotionSettingsAction';
import { PriceDecimalPlacesAction } from './PriceDecimalPlacesAction';

import priceListStyles from './priceList.module.scss';

const PricesImportAction = observer(({ group }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <PricesImportModal
        onImportSuccess={() => {
          group.handlePriceImportSuccess();
        }}
        open={open}
        onClose={handleClose}
        priceGroupId={group.id}
      />
      <Button onClick={handleOpen} disabled={group.promotion?.active}>
        Импорт / экспорт цен
      </Button>
    </>
  );
});

const PriceListActions = observer(({ group }) => {
  const startDate = group.promotion?.startDate;
  const endDate = group.promotion?.endDate;

  const description =
    startDate && endDate
      ? `с ${startDate.format('DD MMM YYYY')} по ${endDate.format(
          'DD MMM YYYY',
        )}, ${
          group.promotion?.allday
            ? 'круглосуточно'
            : `с ${startDate.format('HH:mm')} до ${endDate.format(
                'HH:mm',
              )} (${moment().format('Z')})`
        }`
      : 'не установлены. \nНастройте акцию, чтобы разблокировать акционные цены.';

  return (
    <div className={priceListStyles.actionsContainer}>
      <Space direction="vertical" align="end">
        <div className={priceListStyles.actionsDescription}>
          <b>Текущие настройки акции</b>: {description}{' '}
        </div>
        <Space size={8}>
          <ActivatePromotionAction group={group} />
          <PromotionSettingsAction group={group} />
          <PricesImportAction group={group} />
          <PriceDecimalPlacesAction group={group} />
        </Space>
      </Space>
    </div>
  );
});

function PriceList({ element, onAdd, isLoading }) {
  const { conceptionExtPLU } = element;
  const toDataSource = (price) => ({
    plu: price.plu,
    extPlu: (conceptionExtPLU && conceptionExtPLU[price.drinkId]) || '—',
    key: price.id,
    name: price.name,
    value: price.value,
    promotionPrice: price.promotionPrice,
    nds: price.nds,
    codeExt: price.codeExt,
    currency: price.currency,
    rm: () => {
      element.removePrice(price.id);
      if (price.promotionPrice) {
        element.removePrice(price.promotionPrice.id);
      }
    },
    sendCodeExt: (codeExt) => price.setCodeExt(codeExt),
    sendValue: (value) =>
      price.setValue(value).then(() => {
        element.updateDevicesSyncStatus();
      }),
    promotionId: element.promotion?.id,
    sendPromotionValue: async (value) => {
      if (price.promotionPrice) {
        return price.promotionPrice.setValue(value);
      }

      return element.addPomotionPrice({
        drinkId: price.drinkId,
        value,
      });
    },
  });

  useEffect(() => {
    element.fetchPromotionInfo();
  }, []);

  return (
    <List
      isLoading={isLoading}
      dataSource={element.prices}
      toDataSource={toDataSource}
      columns={element.isActivePromotion ? PROMOTION_COLUMNS : DEFAULT_COLUMNS}
      onAdd={element.isActivePromotion ? undefined : onAdd}
      title={`Список напитков (${element.drinksCount})`}
      actions={<PriceListActions group={element} />}
    />
  );
}

export default inject('element')(observer(PriceList));
