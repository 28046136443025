import Filters from 'models/filters';
import { computed } from 'mobx';

export default class RelatedFilters extends Filters{

	session

	constructor(filters, session){
		super(filters)
		for (const key of Object.keys(this.filters)) {
			if(this.connectIdWithSelector[key]){
				this.filters[key].selector = this.connectIdWithSelector[key].func
				this.filters[key].updateFilters = (args) => this.updateFilters(args, this.connectIdWithSelector[key].key)
			}
			if(key === 'device__sale_point__company__id' || key === 'companyId')
				this.filters[key].clearRelatedFilters = this.clearRelatedFilters.bind(this)
		}
		this.session = session
	}

	clearRelatedFilters(args){
		for (const key of Object.keys(this.connectIdWithSelector)) {
			const arr = this.get(key)
			if(arr?.length){ 
				// удаляем все объекты которые не причаны к выбранным компаниям
				const filteredArr = arr.filter(id => 
					args.includes(this.session[this.connectIdWithSelector[key].key].dataModel.manager.map[id].companyId)
				)
				this.set(key, filteredArr)
			}
		}
	}

	connectIdWithSelector = {
		device__sale_point__id: {func: () => this.objectsFiltered, key: 'points'},
		device__id: {func: () => this.devicesFiltered, key: 'devices'},
		drink__id: {func: () => this.drinksFiltered, key: 'drinks'},
		salePointId: {func: () => this.additionalObjectsFiltered, key: 'points'} // сделано для класса cleans
	}

	updateFilters = (arg, key) => {
		if(this.get('device__sale_point__company__id'))
			arg.forEach(el => {
				if(!this.get('device__sale_point__company__id').includes(this.session[key].dataModel.manager.map[el].companyId))
					this.set('device__sale_point__company__id', 
					this.get('device__sale_point__company__id').concat([this.session[key].dataModel.manager.map[el].companyId]))
			});
		else if(this.get('companyId'))
			arg.forEach(el => {
				if(!this.get('companyId').includes(this.session[key].dataModel.manager.map[el].companyId))
					this.set('companyId', 
					this.get('companyId').concat([this.session[key].dataModel.manager.map[el].companyId]))
			});
	}

	@computed get objectsFiltered () {
    if(this.session?.points?.rawData.length === 0)
      return undefined
    if(this.get('device__sale_point__company__id').length === 0)
      return this.session.points.rawData.map(el => [el.id, el.name])
    return this.session.points.rawData.filter(el => this.get('device__sale_point__company__id').includes(el.companyId)).map(el => [el.id, el.name])
  }

  @computed get devicesFiltered () {
    if(this.session?.devices?.rawData.length === 0)
      return undefined
    if(this.get('device__sale_point__company__id').length === 0)
      return this.session.devices.rawData.map(el => [el.id, el.name])
    return this.session.devices.rawData.filter(el => this.get('device__sale_point__company__id').includes(el.companyId)).map(el => [el.id, el.name])
  }

  @computed get drinksFiltered () {
    if(this.session?.drinks?.rawData.length === 0)
      return undefined
    if(this.get('device__sale_point__company__id').length === 0)
      return this.session.drinks.rawData.map(el => [el.id, el.name])
    return this.session.drinks.rawData.filter(el => this.get('device__sale_point__company__id').includes(el.companyId)).map(el => [el.id, el.name])
  }

	@computed get additionalObjectsFiltered () {
    if(this.session?.points?.rawData.length === 0)
      return undefined
    if(this.get('companyId').length === 0)
      return this.session.points.rawData.map(el => [el.id, el.name])
    return this.session.points.rawData.filter(el => this.get('companyId').includes(el.companyId)).map(el => [el.id, el.name])
  }
}