import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';

import style from './style.module.scss';

function Loader({ fullscreen, size, className }) {
  const classes = classNames(
    style.loader,
    { [style.fillscreen]: fullscreen },
    className,
  );
  const finalSize = size || (fullscreen ? 'large' : 'default');
  return <Spin className={classes} size={finalSize} />;
}

export function EllipsisLoader() {
  return (
    <div className={style['lds-ellipsis-container']}>
      <div className={style['lds-ellipsis']}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loader;
